import { TextReaderButton } from '@/components/TextReaderButton'

import { askEmail } from '../constants'

import * as S from './styles'

export function ASKhelper() {
  return (
    <S.ContainerASKSection id="ajuda">
      <div className="content">
        <S.ContentASK>
          <div className="icon">
            <svg
              width="131"
              height="140"
              viewBox="0 0 131 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="129.385"
                y="52.8048"
                width="88.4465"
                height="87.2406"
                rx="7"
                transform="rotate(105 129.385 52.8048)"
                stroke="#FFFFFF"
                strokeWidth="2"
              />
              <path
                d="M63.209 63.999L63.1953 64.0066L63.1819 64.0146L12.1988 94.5387C8.34401 96.8466 3.63794 93.2945 4.80079 88.9547L27.0142 6.05316C27.7289 3.38583 30.4706 1.80292 33.1379 2.51763L116.05 24.7337C120.388 25.8963 121.113 31.7457 117.189 33.9314L63.209 63.999Z"
                stroke="#FFFFFF"
                strokeWidth="2"
              />
            </svg>
          </div>

          <div className="header-ask">
            <span className="ask-title">Não achou sua dúvida?</span>

            <TextReaderButton
              idReading="home-ASK-helper#1"
              textReading={askEmail}
            />

            <span className="ask-text">
              Entre em contato conosco pelo endereço de e-mail{' '}
              <a href="mailto:entrada.moradia@cidades.ce.gov.br">
                entrada.moradia@cidades.ce.gov.br
              </a>
              .
            </span>
          </div>

          <div className="icon">
            <svg
              width="140"
              height="140"
              viewBox="0 0 140 140"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="108.384"
                y="52.8044"
                width="88.4465"
                height="87.2406"
                rx="7"
                transform="rotate(105 108.384 52.8044)"
                stroke="#FFFFFF"
                strokeWidth="2"
              />
              <path
                d="M74.3869 62.8374L74.3734 62.8293L74.3598 62.8217L22.4336 33.9311C18.5075 31.7467 19.2307 25.8951 23.5705 24.7323L106.472 2.51885C109.139 1.80414 111.881 3.38705 112.596 6.05438L134.812 88.966C135.974 93.3046 131.271 96.8567 127.416 94.5512L74.3869 62.8374Z"
                stroke="#FFFFFF"
                strokeWidth="2"
              />
            </svg>
          </div>
        </S.ContentASK>
      </div>

      <S.TexturaBg $size="auto" />
    </S.ContainerASKSection>
  )
}
