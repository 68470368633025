import styled from 'styled-components'

import BgImgPrimaryTexture from '@/../public/img/textura-principal.png'

export const urlLinkImageBg =
  process.env.NEXT_URL_PUBLIC + `/img/casal-inicial-grande.png`

export const ContainerPresentationSection = styled.section`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `0px ${theme.spacings.large}`};

  > div.content {
    width: 100%;
    max-width: 1140px;

    z-index: 100;
  }
`

export const ImgBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

export const WrapperInfo = styled.div`
  margin: 4.8rem 0px;
  width: 100%;

  display: flex;
  flex-direction: column;

  img {
    user-select: none;
  }
`

export const TexturaBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgPrimaryTexture.src});
  background-repeat: repeat;
  background-size: auto;
  mix-blend-mode: multiply;
  opacity: 0.6;
`

export const MainInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  > div.main-info-right {
    width: 100%;
  }

  > div.main-info-left {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    padding: 48px;
    background-color: ${({ theme }) => theme.colors.brandGreen600};

    font-weight: ${({ theme }) => theme.font.bold};
    font-size: 6.2rem;
    line-height: 5rem;
    color: ${({ theme }) => theme.colors.white};

    > .special {
      color: ${({ theme }) => theme.colors.brandYellow400};
      line-height: 4rem;
      font-size: 4rem;
    }
  }

  @media screen and (max-width: 621px) {
    grid-template-columns: 1fr;
    > div.main-info-right {
      display: none;
    }
  }

  @media screen and (max-width: 395px) {
    > div.main-info-left {
      padding: 18px;
      font-size: 5.2rem;
      > .special {
        font-size: 3rem;
      }
    }
  }
`

export const FooterInfo = styled.div`
  display: flex;

  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0px 0px 48px 48px;

  > div.card-info {
    cursor: pointer;
    user-select: none;
    padding: 27px;
    border-radius: 0px 0px 48px 0px;

    flex: 1;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;

    > div.icon-info {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 2.4rem;
      width: 48px;
      height: 48px;
      border-radius: 48px;

      border: 3px solid ${({ theme }) => theme.colors.brandYellow500};
      color: ${({ theme }) => theme.colors.brandYellow500};
    }

    .wrapper-text {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;

      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: 1.8rem;

      > .link {
        font-family: ${({ theme }) => theme.font.secondary};
        font-weight: ${({ theme }) => theme.font.normal};
        font-size: 1.6rem;
      }
    }

    &.first {
      position: relative;
      border-radius: 0px 0px 0px 48px;
      > div.icon-info {
        border-color: ${({ theme }) => theme.colors.info};
        color: ${({ theme }) => theme.colors.brandOrange500};
      }

      &:hover {
        > div.icon-info svg path {
          fill: white; /* Altera a cor do preenchimento para branco durante o hover */
        }
      }

      &.mid {
        > div.icon-info {
          border-color: ${({ theme }) => theme.colors.brandGreen500};
          color: ${({ theme }) => theme.colors.brandGreen500};
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
        width: 2px;
        height: 50%;
        background-color: #d9d9d9;
      }
    }

    &.mid1 {
      position: relative;
      border-radius: 0px;
      > div.icon-info {
        border-color: ${({ theme }) => theme.colors.brandOrange500};
        color: ${({ theme }) => theme.colors.brandOrange500};
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
        width: 2px;
        height: 50%;
        background-color: #d9d9d9;
      }
    }

    &.mid {
      position: relative;
      border-radius: 0px;
      > div.icon-info {
        border-color: ${({ theme }) => theme.colors.brandGreen500};
        color: ${({ theme }) => theme.colors.brandGreen500};
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
        width: 2px;
        height: 50%;
        background-color: #d9d9d9;
      }
    }

    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.brandGreen500};
      color: ${({ theme }) => theme.colors.white};

      > div.icon-info {
        color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media screen and (max-width: 500px) {
    > div.card-info {
      padding: 17px;
      border-radius: 0px 0px 48px 48px;

      > div.icon-info {
        font-size: 2rem;
        width: 35px;
        height: 35px;
        border-radius: 35px;

        border: 1px solid ${({ theme }) => theme.colors.brandOrange500};
        color: ${({ theme }) => theme.colors.brandOrange500};
      }

      &.first {
        position: relative;
        border-radius: 0px 0px 0px 0px;
      }
    }
  }

  @media screen and (max-width: 490px) {
    flex-direction: column;
  }
`
