import { useState } from 'react'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import Link from 'next/link'

import { TextReaderButton } from '@/components/TextReaderButton'

import { sessaoDuvidas } from '../constants'

import { DATA_FAQ } from './dataFaq'

import * as S from './styles'

export function FAQSection() {
  const [openId, setOpenId] = useState<string | undefined>(undefined)
  const [indexBackId, setIndexBackId] = useState<number | undefined>(undefined)

  const handleOpen = (valueId: string) => {
    setOpenId((prev) => (prev === valueId ? undefined : valueId))
  }

  return (
    <S.ContainerFAQSection id="duvidas">
      <div className="content">
        <S.ContentFAQ>
          <div className="header-faq">
            <span className="faq-title">DÚVIDAS</span>

            <TextReaderButton
              idReading={`home-FAQ-section-title#1`}
              textReading={sessaoDuvidas}
            />
            <span className="faq-text">
              Encontre respostas rápidas para suas perguntas mais comuns.
            </span>

            <Link
              className="faq-text link"
              href={'/documentos-oficiais#duvidas'}
            >
              Veja também a seção de dúvidas e respostas para empresas. ↗
            </Link>

            <div className="wrapper-svg">
              <div>
                <svg
                  width="131"
                  height="140"
                  viewBox="0 0 131 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="129.385"
                    y="52.8048"
                    width="88.4465"
                    height="87.2406"
                    rx="7"
                    transform="rotate(105 129.385 52.8048)"
                    stroke="#ADB5BD"
                    strokeWidth="2"
                  />
                  <path
                    d="M63.209 63.999L63.1953 64.0066L63.1819 64.0146L12.1988 94.5387C8.34401 96.8466 3.63794 93.2945 4.80079 88.9547L27.0142 6.05316C27.7289 3.38583 30.4706 1.80292 33.1379 2.51763L116.05 24.7337C120.388 25.8963 121.113 31.7457 117.189 33.9314L63.209 63.999Z"
                    stroke="#ADB5BD"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div>
                <svg
                  width="140"
                  height="140"
                  viewBox="0 0 140 140"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="108.384"
                    y="52.8044"
                    width="88.4465"
                    height="87.2406"
                    rx="7"
                    transform="rotate(105 108.384 52.8044)"
                    stroke="#ADB5BD"
                    strokeWidth="2"
                  />
                  <path
                    d="M74.3869 62.8374L74.3734 62.8293L74.3598 62.8217L22.4336 33.9311C18.5075 31.7467 19.2307 25.8951 23.5705 24.7323L106.472 2.51885C109.139 1.80414 111.881 3.38705 112.596 6.05438L134.812 88.966C135.974 93.3046 131.271 96.8567 127.416 94.5512L74.3869 62.8374Z"
                    stroke="#ADB5BD"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="wrapper-faq">
            {DATA_FAQ.map(({ idKey, question, answer }, index) => (
              <S.FaqContent
                key={idKey}
                $isOpen={openId === idKey}
                $adjustBorder={indexBackId === index}
                aria-live="polite"
              >
                <div
                  className="header"
                  onClick={() => {
                    handleOpen(idKey)
                    setIndexBackId(index - 1)
                  }}
                  tabIndex={0}
                  role="button"
                  aria-expanded={openId === idKey}
                  aria-controls={`faq-answer-${idKey}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div className="icon">
                    {openId === idKey ? (
                      <MinusCircleOutlined />
                    ) : (
                      <PlusCircleOutlined />
                    )}
                  </div>

                  <span className="question">{question}</span>

                  <span className="button">
                    <TextReaderButton
                      idReading={`home-FAQ-section-question-and-answer#${idKey}`}
                      textReading={question + ' ' + answer}
                    />
                  </span>
                </div>
                <div id={`faq-answer-${idKey}`} className="main">
                  <span>{answer}</span>
                </div>
              </S.FaqContent>
            ))}
          </div>
        </S.ContentFAQ>
      </div>

      <S.TexturaBg $size="auto" />
    </S.ContainerFAQSection>
  )
}
