export const DATA_FAQ = [
  {
    idKey: 'faq_1',
    question: 'O que é o Programa Entrada Moradia Ceará?',
    answer: `O Programa Entrada Moradia Ceará quer tornar possível a compra da casa própria, em parceria com o Governo Federal e a iniciativa privada e por meio da transferência de recursos financeiros do Governo do Estado do Ceará.\n
    A intenção dessa parceria é facilitar o acesso das pessoas nos financiamentos concedidos pelo Sistema Financeiro de Habitação (SFH) e do Programa MInha Casa, Minha Vida ou outro que o substitua no futuro.\n
    Você pode usar o benefício para reduzir ou zerar o valor da entrada que você vai dar para financiar o seu imóvel.\n
    Além disso, as construtoras deverão liberar você das custas com o Imposto de Transmissão de Bens Imóveis (ITBI) e o registro do imóvel.
    `
  },
  {
    idKey: 'faq_2',
    question: 'Quem pode participar do Programa Entrada Moradia Ceará?',
    answer: `Para participar do Programa, você precisa:\n
    - Ter renda bruta familiar de até R$4.400,00 (quatro mil e quatrocentos reais);\n
    - ter mais de 18 anos ou ser emancipado;\n
    - morar no Estado do Ceará há pelo menos 1 ano;\n
    - ter nascido no Brasil ou ser naturalizada brasileira e estrangeiros e estrangeiras*;\n
    - não possuir, atualmente, nenhum imóvel;\n
    - não possuir o direito de comprar um imóvel (cessionário);\n
    - não ter o direito de fazer uso nem de aproveitar nenhum imóvel de outra pessoa (usufrutuário);\n
    - não ter o direito de uso para utilizar o imóvel de outra pessoa (arrendatário);\n
    - não ter nenhum tipo de contrato de "Promessa de Compra e Venda" de imóvel para morar, urbano ou rural (promitente comprador);\n
    - não ter sido beneficiado por atendimento habitacional definitivo em nenhum programa habitacional de interesse social em qualquer lugar do país;\n
    - ter avaliação inicial de crédito aprovada pelo Correspondente Bancário ou funcionário da Caixa Econômica Federal;\n
    - ter interesse em comprar um imóvel cadastrado no Programa Entrada Moradia Ceará.\n
    \n
    * Se você for estrangeiro(a) e quiser participar do Programa Entrada Moradia Ceará, você deve se informar para saber se preenche os requisitos definidos pela Caixa Econômica Federal.
    `
  },
  {
    idKey: 'faq_3',
    question: 'Que tipos de imóveis podem ser financiados pelo Programa?',
    answer: `Para comprar imóveis pelo Programa Entrada Ceará Moradia, você só poderá usar o benefício recebido para dar entrada em imóveis novos.\n
      Estes imóveis precisam estar listados nos empreendimentos aprovados pelo Programa e não podem custar mais que o valor total estabelecido para o Programa Minha Casa, Minha Vida - Faixa 2.\n
      Para ver a lista de imóveis cadastrados, clique no botão "Empreendimentos".
      `
  },
  {
    idKey: 'faq_4',
    question: 'Qual o tipo de infraestrutura será entregue com a moradia?',
    answer: `Os imóveis cadastrados no Programa Entrada Moradia Ceará deverão, obrigatoriamente, oferecer infraestrutura de:\n
    - abastecimento de água;\n
    - esgotamento sanitário;\n
    - energia elétrica;\n
    - iluminação pública;\n
    - instalações telefônicas;\n
    - redes de drenagem de águas pluviais (água da chuva);\n
    -pavimentação.\n
    \n
    É importante lembrar que serão atendidas as necessidades da região que vai receber o imóvel.
    `
  },
  {
    idKey: 'faq_5',
    question: 'Irei participar de algum sorteio para receber os 20 mil reais?',
    answer:
      'Não. Se você cumprir todos os requisitos do Programa Entrada Moradia Ceará e do Programa Minha Casa, Minha Vida, os R$20.000,00 serão liberados diretamente para a Caixa Econômica Federal diminuir o valor da entrada do imóvel que você escolheu.'
  },
  {
    idKey: 'faq_6',
    question:
      'Quais as informações obrigatórias que preciso preencher para me cadastrar no Programa?',
    answer: `(!) Mantenha o seu cadastro sempre atualizado. Isto é muito importante para que a gente possa entrar em contato com você sempre que necessário.\n
    (!) Todas as informações serão enviadas por e-mail.\n
    **Primeiro você precisará informar o número de avaliação de crédito e o APF do empreendimento onde pretende comprar um imóvel.**\n
    **Depois serão solicitados os seguintes dados:**
    -> Responsável familiar:\n
      - CPF;\n
      - e-mail;\n
      - nome completo do responsável familiar;\n
      - nome completo da mãe do responsável familiar;\n
      - data de nascimento;\n
      - estado civil;\n
      - tipo de renda;\n
      - valor da renda bruta* em reais;\n
      - número de celular;\n
      - endereço completo: nome da avenida, rua, travessa, número, bairro, cidade, Estado e CEP;\n
    -> Marido/esposa/companheiro/companheira (se houver)\n
      - CPF;\n
      - nome completo;\n
      - nome completo da mãe;\n
      - data de nascimento;\n
      - estado civil;\n
      - tipo de renda;\n
      - valor da renda* bruta em reais;\n
      - número de celular;\n
      * Renda bruta é o valor total que você recebe, sem os descontos obrigatórios (de impostos, por exemplo).
      - Certidão Negativa Municipal - do município que reside, 
      - Certidão Negativa Municipal - do município do imóvel escolhido,
      - Certidão Negativa Estadual
    `
  },
  {
    idKey: 'faq_7',
    question: 'Como funciona o Programa Entrada Moradia Ceará?',
    answer: `O Programa Entrada Moradia Ceará oferece um benefício no valor de R$20.000,00 para os moradores do Estado do Ceará que recebam até R$4.400,00.\n
    O dinheiro deve ser usado como pagamento da entrada na compra do primeiro imóvel, financiado pelo Programa Minha Casa, Minha Vida.\n
    Com isso, o valor total da entrada do imóvel é reduzida ou mesmo zerada, tornando possível e acessível para mais famílias a compra do seu primeiro imóvel.\n
    Além disso, as construtoras deverão liberar você das custas com o Imposto de Transmissão de Bens Imóveis (ITBI) e o registro do imóvel.
    `
  },
  {
    idKey: 'faq_8',
    question:
      'Sou cearense. Posso participar se eu morar em outro estado do Brasil?',
    answer: `Não. Só podem participar do  Programa Entrada Moradia Ceará pessoas que morem no Estado do Ceará há pelo menos 1 ano.\n
    (!) Você pode ter nascido em qualquer estado do Brasil, ser brasileiro naturalizado ou estrangeiro.
    `
  },
  {
    idKey: 'faq_9',
    question:
      'Como posso usar os R$20.000,00 do Programa Entrada Moradia Ceará?',
    answer:
      'Você pode usar o dinheiro para diminuir ou zerar o valor da entrada que você vai dar para financiar o seu imóvel.'
  },
  {
    idKey: 'faq_10',
    question: 'Em quanto tempo o dinheiro será liberado?',
    answer: `Não é possível determinar um prazo exato. Depois que as etapas que dependem de você e da Caixa Econômica Federal terminarem, a Secretaria das Cidades deve levar 15 dias úteis para analisar o pedido.\n
    (!) A quantidade de dias pode variar, a depender do volume de pedidos.
    `
  },
  {
    idKey: 'faq_11',
    question: 'Moro no Ceará, mas não sou cearense. Posso participar?',
    answer: `SIm. Para participar, você pode ser:\n
    - brasileiro(a) nato(a) (é brasileiro(a) desde o momento em que nasceu);\n
    - brasileiro(a) naturalizado(a) (quando você consegue a nacionalidade brasileira depois de um processo de naturalização. Explicando melhor: você é estrangeiro(a) e conseguiu a nacionalidade de um país diferente do que você nasceu);\n
    - estrangeiro ou estrangeira.\n
    \n
    Se você for estrangeiro(a) e quiser participar do Programa Entrada Moradia Ceará, você deve se informar para saber se preenche os requisitos definidos pela Caixa Econômica Federal.
    `
  },
  {
    idKey: 'faq_12',
    question:
      'Meu filho mora comigo e trabalha. A renda dele também pode entrar para somar à nossa renda?',
    answer: `A regra geral é que seja apresentada a renda do responsável financeiro (a pessoa que for fazer o cadastro) e se a pessoa for casada ou viver em união estável, pode juntar essa renda também.\n
    A depender do valor do imóvel, a Caixa Econômica Federal irá orientar o responsável financeiro da melhor forma sobre incluir ou não outros familiares.
    `
  },
  {
    idKey: 'faq_13',
    question: 'Preciso anexar algum documento para me cadastrar no Programa?',
    answer: `
    Sim. Você deverá incluir:\n
    - um documento atual com foto que comprove a sua identidade e a do seu companheiro ou da sua companheira, se for o caso;\n
    - dois comprovantes de endereço: um atual e outro de, pelo menos, 12 meses atrás, para comprovar que reside no Estado do Ceará há pelo menos 1 ano. Explicando melhor: se o comprovante de endereço atual for do mês de janeiro de 2024, você vai precisar anexar um comprovante de janeiro de 2023 ou mais antigo. Os comprovantes de endereço não precisam ser da mesma empresa e podem ser em nome de qualquer um dos membros familiares que for receber o benefício. Observação: serão aceitos qualquer documento para comprovar onde você mora (água, luz, telefone, gás, fatura de cartão de crédito, entre outros);\n
    - comprovante de que é vítima de violência doméstica, se for o seu caso. Serão aceitos, por exemplo, o boletim de ocorrência (BO) ou uma decisão judicial de medida protetiva;\n
    - comprovante de que é mãe solo atípica, se for o seu caso. Mãe solo atípica é a mulher que, sem ter um companheiro ou companheira, cuida do filho ou da filha com algum tipo de deficiência. Exemplos de documentos que serão aceitos: laudo médico da pessoa que precisa de cuidados especiais com a Classificação Internacional de Doenças (CID) e comprovante de inscrição no Cadastro Único (CadÚnico);\n
    - comprovante de que sua família é chefiada por uma mulher e está em situação de vulnerabilidade social. Exemplos de documentos que serão aceitos: estudo, relatório, laudo, parecer ou perícia social de alguma entidade pública (CRAS, CAPS, CREAS, Conselhos de Assistência Social,  Secretarias de Assistência Social, Defesa Civil, CadÚnico, entre outros).
    `
  },
  {
    idKey: 'faq_14',
    question:
      'O que faço se minha avaliação de crédito não for aprovada pela Caixa Econômica Federal?',
    answer: 'Você pode escolher outro empreendimento e tentar novamente.'
  },
  {
    idKey: 'faq_15',
    question:
      'Só pode participar do Programa quem trabalhar com carteira assinada?',
    answer:
      'Não. Quando você fizer o seu cadastro, terá uma pergunta sobre o tipo de renda que você recebe.'
  },
  {
    idKey: 'faq_16',
    question:
      'Se eu tiver dinheiro guardado e conseguir o benefício, posso juntar esses valores para dar de entrada no imóvel?',
    answer: `Sim. O benefício do Programa é cumulativo com outros benefícios e recursos que você tenha guardado (por exemplo, poupança ou FGTS).\n
    O que vai definir a possibilidade de compra do imóvel é a sua capacidade de endividamento (se você vai conseguir pagar as parcelas do financiamento, pois você só pode comprometer até 33% de sua renda).\n
    `
  },
  {
    idKey: 'faq_17',
    question: 'O que é o Acesso Cidadão?',
    answer:
      'O Acesso Cidadão é um ponto de acesso unificado entre serviços e plataformas do Governo do Ceará. Para mais informações acesse as dúvidas frequentes sobre o Acesso Cidadão. ↗'
  }
]
