import styled from 'styled-components'

import BgImgPrimaryTexture from '@/../public/img/textura-principal.png'

export const TexturaBg = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgPrimaryTexture.src});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 0;
`

export const ContainerProgramSection = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${({ theme }) => theme.colors.greenGray400};
  padding: ${({ theme }) => `0px ${theme.spacings.large}`};

  div.content {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    z-index: 1;
  }
`

export const GridVideo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  grid-gap: 24px;
  padding: 80px 0px 110px;

  > div.grid-video-left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > div {
      display: flex;
      flex-direction: column;

      color: ${({ theme }) => theme.colors.white};

      > .container-text-reader-button {
        margin-bottom: 8px;
      }

      > span.title {
        font-family: ${({ theme }) => theme.font.primary};
        font-size: 2.8rem;
        margin-bottom: 8px;
      }

      > span.description {
        font-family: ${({ theme }) => theme.font.secondary};
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 32px;
      }
    }
  }

  > div.grid-video-right {
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`
