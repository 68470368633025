import styled, { css } from 'styled-components'

export const ContainerHowToParticipateRequirement = styled.div`
  margin: 1.6rem 0px 10rem;

  width: 100%;

  padding: 2.4rem 3.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.greenGray100};
  border-radius: 8px;

  > div.requirement-company {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2.4rem;

    margin-bottom: 3.6rem;

    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: 1.8rem;
    line-height: 3rem;

    color: ${({ theme }) => theme.colors.black};

    a {
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.black};

      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.colors.brandGreen500};
      }
    }
  }
`

type TypeContainer = {
  $openRequirementsCitizen: boolean
  $openRequirementsStep: boolean
}

export const ContainerHowToUserRequirement = styled.div<TypeContainer>`
  margin: 1.6rem 0px 10rem;

  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.greenGray100};
  border-radius: 8px;

  > div.divisor {
    position: relative;
    width: 100%;

    &::after {
      position: absolute;
      content: '';

      position: absolute;
      top: -1px;
      left: 3.2rem;

      width: calc(100% - 6.4rem);
      height: 2px;
      background-color: ${({ theme }) => theme.colors.greenGray100};
    }
  }

  > div.card-requirement-user {
    > div.header-card {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      padding: 2.4rem 3.2rem;
      border-radius: 6px;

      background-color: ${({ theme }) => theme.colors.white};

      transition: background-color 200ms ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray200};
      }
    }

    > div.wrapper-info-requirement-user {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10rem;
      padding: 3rem 3.2rem 7rem;

      display: none;
      ${({ $openRequirementsCitizen }) =>
        $openRequirementsCitizen &&
        css`
          display: grid;
        `}
    }

    > div.wrapper-info-requirement-step {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10rem;
      padding: 3rem 3.2rem 7rem;

      display: none;
      ${({ $openRequirementsStep }) =>
        $openRequirementsStep &&
        css`
          display: grid;
        `}
    }
  }

  @media screen and (max-width: 625px) {
    > div.card-requirement-user {
      > div.wrapper-info-requirement-user {
        grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
      }

      > div.wrapper-info-requirement-step {
        grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
      }
    }
  }

  @media screen and (max-width: 430px) {
    > div.card-requirement-user {
      > div.wrapper-info-requirement-user {
        grid-template-columns: 1fr;
        grid-gap: 5rem;
      }

      > div.wrapper-info-requirement-step {
        grid-template-columns: 1fr;
        grid-gap: 5rem;
      }
    }
  }
`

export const CardRequirement = styled.div<{ $rotated: number }>`
  width: 100%;

  > div.wraper-geo-img {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.4rem;

    > span {
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: 12rem;
      color: ${({ theme }) => theme.colors.brandGreen500};
      z-index: 50;
    }

    .rotated {
      transform: rotate(${({ $rotated }) => $rotated}deg);
    }

    ${({ $rotated }) =>
      $rotated === -1 &&
      css`
        display: none;
      `}
  }

  div.text-card {
    width: 100%;
    text-align: center;

    white-space: pre-line;
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.green700};
    line-height: 2.2rem;
    text-align: center;
  }
`

export const CardRequirementStep = styled.div<{ $rotated: number }>`
  width: 100%;

  > div.wraper-geo-img {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.4rem;

    > span {
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: 12rem;
      color: ${({ theme }) => theme.colors.brandGreen500};
      z-index: 50;
    }

    .rotated {
      position: absolute;
      transform: rotate(${({ $rotated }) => $rotated}deg);
    }

    ${({ $rotated }) =>
      $rotated === -1 &&
      css`
        display: none;
      `}
  }

  div.text-card {
    width: 100%;
    text-align: center;

    white-space: pre-line;
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.black};
    line-height: 2.2rem;
    text-align: center;

    a {
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.black};
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.colors.brandGreen500};
      }
    }
  }
`
