import styled from 'styled-components'

export const ContainerTextReaderButton = styled.div`
  .btn-text-reader {
    border-radius: 15px;
  }

  .icon-text-reader {
    font-size: 1.6rem;
    margin-left: 8px;
  }
`
