import { useState } from 'react'
import Link from 'next/link'
import { ShopOutlined, UserOutlined } from '@ant-design/icons'

import { TextReaderButton } from '@/components/TextReaderButton'

import HowToParticipateRequirement from '../HowToParticipateRequirement'
import { comoParticipar } from '../constants'

import * as S from './styles'

export function HowToParticipateSection() {
  const [typeToggle, setTypeToggle] = useState<'user' | 'company'>('user')

  return (
    <S.ContainerHowToParticipateSection id="como-participar">
      <div className="content">
        <S.HeaderHowToParticipate>
          <span className="title-item">COMO PARTICIPAR</span>
          <TextReaderButton
            idReading="home-how-to-participate-section#1"
            textReading={comoParticipar}
          />

          <span className="description-item">
            <strong>
              Podem participar cidadãos que estejam em conformidade com as
              regras do programa
            </strong>{' '}
            e empresas que tenham interesse em cadastrar seus empreendimentos.
          </span>

          <S.ToggleType
            $type={typeToggle}
            onClick={() =>
              setTypeToggle((prev) => {
                if (prev === 'user') return 'company'
                return 'user'
              })
            }
          >
            <div className="tootle-user">
              <UserOutlined />
              <span>SOU CIDADÃO</span>
            </div>
            <div className="tootle-company">
              <span>SOU EMPRESA</span>
              <ShopOutlined />
            </div>
          </S.ToggleType>

          <Link href="/documentos-oficiais" className="link-lei">
            Saiba mais detalhes sobre o programa na página da lei e do decreto.
            ↗
          </Link>
        </S.HeaderHowToParticipate>

        <HowToParticipateRequirement type={typeToggle} />
      </div>

      <S.TexturaBg $size="auto" />
    </S.ContainerHowToParticipateSection>
  )
}
