import styled, { css } from 'styled-components'

import BgImgPrimaryTexture from '@/../public/img/textura-principal.png'

export const ContainerHowToParticipateSection = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `0px ${theme.spacings.large}`};
  > div.content {
    width: 100%;
    max-width: 1140px;

    z-index: 100;
  }
`

export const TexturaBg = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgPrimaryTexture.src});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
`

export const HeaderHowToParticipate = styled.div`
  margin-top: 9.4rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;

  span {
    max-width: 730px;
    text-align: center;
  }

  span.title-item {
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.brandGreen500};
  }

  span.description-item {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: 1.8rem;
    line-height: 2.9rem;
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.black};

    strong {
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.brandOrange500};
    }
  }

  > a.link-lei {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.brandGreen500};
    }
  }
`

export const ToggleType = styled.div<{ $type: 'user' | 'company' }>`
  box-shadow: 0px 0px 24px 0px #0000004d;

  margin: 3rem 0px 1.5rem;

  position: relative;
  cursor: pointer;

  display: flex;
  gap: 6.3rem;
  padding: 1.2rem 2.4rem;
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.brandOrange500};

  > div {
    display: flex;
    gap: 1.2rem;
    z-index: 50;

    transition: color 350ms ease-in-out;
  }

  ${({ $type }) =>
    $type === 'user'
      ? css`
          > div.tootle-user {
            color: ${({ theme }) => theme.colors.white};
          }
        `
      : css`
          > div.tootle-company {
            color: ${({ theme }) => theme.colors.white};
          }
        `}

  &::after {
    content: '';
    position: absolute;
    top: -3px;
    left: ${({ $type }) => ($type === 'user' ? -1 : 51)}%;

    width: 50%;
    height: calc(100% + 6px);
    background-color: ${({ theme }) => theme.colors.green400};
    border: 2px solid ${({ theme }) => theme.colors.black};
    border-radius: 8px;

    z-index: 20;
    transition: left 400ms ease-in-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray200};

    ${({ $type }) =>
      $type === 'user'
        ? css`
            > div.tootle-company {
              color: ${({ theme }) => theme.colors.brandGreen500};
            }
          `
        : css`
            > div.tootle-user {
              color: ${({ theme }) => theme.colors.brandGreen500};
            }
          `}
  }
`
