import styled from 'styled-components'

const basePath = process.env.NEXT_PUBLIC_BASE_PATH || ''
const urlLinkBg = `${basePath}/svg/bg-white.svg`
export const TexturaBg = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${urlLinkBg});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
`
export const ContainerASKSection = styled.section`
  position: relative;

  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding: ${({ theme }) => `0px ${theme.spacings.large} 8rem`};
  background-color: ${({ theme }) => theme.colors.brandOrange500};

  > div.content {
    width: 100%;
    max-width: 1140px;
    margin-top: 9rem;

    z-index: 100;
  }
`

export const ContentASK = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 5.5rem;

  > div.header-ask {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    div.container-text-reader-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .wrapper-svg {
      margin-top: 14rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 17rem;
    }

    > .ask-title {
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: 4.8rem;
      color: ${({ theme }) => theme.colors.white};
    }

    > .ask-text {
      text-align: center;
      font-family: ${({ theme }) => theme.font.secondary};
      font-weight: ${({ theme }) => theme.font.normal};
      font-size: 1.8rem;
      color: ${({ theme }) => theme.colors.white};
      line-height: 3rem;
      max-width: 674px;

      a {
        font-weight: ${({ theme }) => theme.font.bold};
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.white};

        transition: color 200ms ease-in-out;
        &:hover {
          color: ${({ theme }) => theme.colors.brandYellow400};
        }
      }
    }
  }

  > div.wrapper-ask {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 630px) {
    grid-template-columns: 1fr;
    .icon {
      display: none;
    }
  }
`
