import Image from 'next/image'
import Link from 'next/link'

import { TextReaderButton } from '@/components/TextReaderButton'

import { sessaoUm, sessaoDois } from '../constants'

import * as S from './styles'

import logoMoradia from '../../../../public/img/logo-entrada-moradia-ceara-negativo-05.png'
import imgFamily from '../../../../public/img/pagina-inicial/familia.png'
import imgCasa from '../../../../public/img/oficiais/1B2A4560.png'

export function ProgramSection() {
  return (
    <S.ContainerProgramSection id="o-programa">
      <div className="gradient-program">
        <div className="content">
          <S.HeaderProgram>
            <div className="header-program-left">
              <div className="wrapper-img">
                <Image
                  alt=""
                  src={imgFamily}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                  quality={60}
                />
              </div>
            </div>
            <div className="header-program-right">
              <div className="header-program-img">
                <div>
                  <Image
                    alt=""
                    src={logoMoradia}
                    style={{
                      width: '100%',
                      maxWidth: '200px',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                    priority
                    quality={60}
                  />
                </div>
              </div>

              <div className="header-program-title">
                <TextReaderButton
                  idReading="home-program-section#1"
                  textReading={sessaoUm}
                />

                <span>
                  O <span>Programa Entrada Moradia Ceará</span> quer tornar
                  possível a compra da sua casa própria!
                </span>
              </div>
              <div className="header-program-description">
                <span>
                  Em parceria com o Governo Federal e a iniciativa privada, por
                  meio da transferência de recursos financeiros do Governo do
                  Estado do Ceará.
                </span>
              </div>
            </div>
            <S.TexturaBg2 $size="auto" />
          </S.HeaderProgram>
        </div>
      </div>

      <div className="solid-program ">
        <div className="content">
          <S.MainProgram>
            <div className="header-main-program">
              <div className="header-main-program-left">
                <span>
                  Você pode usar o benefício para{' '}
                  <strong>reduzir ou zerar</strong> o valor da entrada no
                  financiamento de seu 1° imóvel e <strong>zerar</strong> os
                  custos com o Imposto de Transmissão de Bens Imóveis (ITBI) e o
                  registro do imóvel.
                </span>
              </div>
              <div className="header-main-program-right">
                <div className="square"></div>
                <div className="triangle">
                  <svg
                    width="180"
                    height="145"
                    viewBox="0 0 180 145"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M86.8013 92.6894L86.7879 92.6814L86.7742 92.6738L3.84206 46.5321C-0.0840607 44.3477 0.639129 38.4961 4.97893 37.3332L137.113 1.92802C139.78 1.2133 142.522 2.79622 143.237 5.46355L178.645 137.608C179.807 141.946 175.104 145.498 171.249 143.193L86.8013 92.6894Z"
                      stroke="#ADB5BD"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="main-program-img">
              <Image
                alt=""
                src={imgCasa}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                quality={60}
              />
            </div>

            <div className="content-btn-text-reader">
              <TextReaderButton
                idReading="home-program-section#2"
                textReading={sessaoDois}
              />
            </div>

            <div className="main-program-grid-info">
              <div className="item-grid">
                <span>
                  Nosso principal objetivo é{' '}
                  <strong>
                    ajudar famílias avaliadas pela Caixa com renda de até
                    R$4.400,00
                  </strong>{' '}
                  (quatro mil e quatrocentos reais) a comprar o seu 1º imóvel.
                  Isso será possível por meio de benefícios no valor de
                  R$20.000,00 (vinte mil reais), vindos do Governo do Estado do
                  Ceará e com o imóvel financiado pelo Programa Minha Casa,
                  Minha Vida.
                </span>

                <Link href={'/documentos-oficiais'}>
                  Conheça todos os critérios para sua família participar do
                  programa. ↗
                </Link>
              </div>
              <div className="item-grid">
                <span>
                  Todos os empreendimentos listados no Programa também estão
                  cadastrados na <strong>Caixa Econômica Federal</strong>, que
                  faz a avaliação técnica e financeira, garantindo a segurança
                  jurídica e financeira da cidadã e do cidadão que deseja
                  comprar um imóvel com o benefício dado pelo Governo do Estado
                  do Ceará.
                </span>

                <Link href={'/empreendimentos'}>
                  Acesse a página com a lista de empreendimentos cadastrados no
                  Programa. ↗
                </Link>
              </div>
            </div>
          </S.MainProgram>
        </div>
      </div>
      <S.TexturaBg $size="auto" />
    </S.ContainerProgramSection>
  )
}
