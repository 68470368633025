export const sessaoUm =
  'O programa Entrada Moradia Ceará quer tornar possível a compra da sua casa própria. Em parceria com o Governo Federal e a iniciativa privada, por meio da transferência de recursos financeiros do Governo do Estado do Ceará. Você pode usar o benefício para reduzir ou zerar o valor da entrada no financiamento de seu 1o imóvel e zerar os custos com o Imposto de Transmissão de Bens Imóveis (I T B I) e o registro do imóvel.'

export const sessaoDois =
  'Nosso principal objetivo é ajudar famílias avaliadas pela Caixa com renda de até R$4.400,00 a comprar o seu 1o imóvel. Isso será possível por meio de benefícios no valor de R$20.000,00, vindos do Governo do Estado do Ceará e com o imóvel financiado pelo Programa Minha Casa, Minha Vida. Todos os empreendimentos listados no Programa também estão cadastrados na Caixa Econômica Federal, que faz a avaliação técnica e financeira, garantindo a segurança jurídica e financeira da cidadã e do cidadão que deseja comprar um imóvel com o benefício dado pelo Governo do Estado do Ceará.'

export const requisitosCidadao =
  'Requisitos para o cidadão - Ter renda bruta familiar de até R$4.400,00. Morar no Estado do Ceará há pelo menos 1 ano. Não possuir, não ter direito e não estar em processo de compra de um imóvel. Não ter sido beneficiado por atendimento habitacional definitivo em nenhum programa habitacional de interesse social em qualquer lugar do país. Ter avaliação inicial de crédito aprovada pelo Correspondente Bancário ou funcionário da Caixa Econômica Federal. Fazer o cadastro no Programa Entrada Moradia Ceará. Ter o cadastro aprovado pela Secretaria das Cidades. Ter a aprovação da operação de crédito individual pela Caixa Econômica Federal. Ter recursos orçamentário e financeiros disponíveis no Governo do Estado do Ceará'
export const passoAPassoCidadao =
  'Passo a passo para o cidadão - Passo 1: Veja as moradias no site: www.entradamoradia.ce.gov.br -  visite os empreendimentos credenciados e escolha um. Passo 2: Simule o financiamento com um correspondente bancário ou funcionário da Caixa. Se o crédito for pré-aprovado, solicite: O número da avaliação de crédito e o O código de Acompanhamento de Programa de Fomento (APF) do imóvel. Passo 3: Cadastre-se no site do programa, envie os documentos solicitados e o Termo de Adesão e Compromisso assinados. Passo 4: Com tudo certo, a Secretaria das Cidades disponibilizará o seu Comprovante de Cadastro de Interesse (CCI). Passo 5: Acompanhe, com seu correspondente bancário, a tramitação do processo de Financiamento junto à Caixa. Passo 6: Nesse passo, será feita a liberação do certificado de subsídio. Em seguida, você assinará o contrato de financiamento.'

export const videoDuvidas =
  'Conheça mais sobre o Programa! Baixe o folder explicativo com o passo a passo de como solicitar seu benefício no botão a seguir.'
export const comoParticipar =
  'COMO PARTICIPAR? Podem participar cidadãos que estejam em conformidade com as regras do programa, e empresas que tenham interesse em cadastrar seus empreendimentos.'

export const sessaoDuvidas =
  'Está com dúvidas? Encontre respostas rápidas para suas perguntas mais comuns clicando no link abaixo. Veja também a seção de dúvidas e respostas para empresas.'

export const empresasCredenciamento =
  'Credencie sua empresa no Programa Entrada Moradia Ceará, acessando a página de cadastro. Esta área é exclusiva para cadastrar empresas do ramo da construção civil que tenham interesse em apresentar imóveis aprovados pela Caixa Econômica Federal que cumpram os requisitos do Programa Minha Casa, Minha Vida. O objetivo desta seção é atender aos beneficiários do Programa Entrada Moradia Ceará.  Saiba mais detalhes sobre o programa na página da lei e do decreto.'

export const faq1 =
  'O que é o Programa Entrada Moradia Ceará? O Programa Entrada Moradia Ceará quer tornar possível a compra da casa própria, em parceria com o Governo Federal e a iniciativa privada e por meio da transferência de recursos financeiros do Governo do Estado do Ceará. A intenção dessa parceria é facilitar o acesso das pessoas nos financiamentos concedidos pelo Sistema Financeiro de Habitação (SFH) e do Programa MInha Casa, Minha Vida ou outro que o substitua no futuro. Você pode usar o benefício para reduzir ou zerar o valor da entrada que você vai dar para financiar o seu imóvel. Além disso, as construtoras deverão liberar você das custas com o Imposto de Transmissão de Bens Imóveis (ITBI) e o registro do imóvel.'

export const faq2 =
  'Para participar do Programa, você precisa: 1º - Ter renda bruta familiar de até R$4.400,00. 2º - Ter mais de 18 anos ou ser emancipado. 3º - Morar no Estado do Ceará há pelo menos 1 ano. 4º - Ter nascido no Brasil ou ser naturalizada brasileira e estrangeiros e estrangeiras. 5º - Não possuir, atualmente, nenhum imóvel. 6º - Não possuir o direito de comprar um imóvel (cessionário). 7º - Não ter o direito de fazer uso nem de aproveitar nenhum imóvel de outra pessoa (usufrutuário). 8ª - Não ter o direito de uso para utilizar o imóvel de outra pessoa (arrendatário). 9º - Não ter nenhum tipo de contrato de "Promessa de Compra e Venda" de imóvel para morar, urbano ou rural (promitente comprador). 10º - Não ter sido beneficiado por atendimento habitacional definitivo em nenhum programa habitacional de interesse social em qualquer lugar do país. 11º - ter avaliação inicial de crédito aprovada pelo Correspondente Bancário ou funcionário da Caixa Econômica Federal. 12º - ter interesse em comprar um imóvel cadastrado no Programa Entrada Moradia Ceará. IMPORTANTE! Se você for estrangeiro(a) e quiser participar do Programa Entrada Moradia Ceará, você deve se informar para saber se preenche os requisitos definidos pela Caixa Econômica Federal.'
export const faq3 =
  'Que tipos de imóveis podem ser financiados pelo Programa? Para comprar imóveis pelo Programa Entrada Ceará Moradia, você só poderá usar o benefício recebido para dar entrada em imóveis novos. Estes imóveis precisam estar listados nos empreendimentos aprovados pelo Programa e não podem custar mais que o valor total estabelecido para o Programa Minha Casa, Minha Vida - Faixa 2. Para ver a lista de imóveis cadastrados, clique no botão "Empreendimentos".'

export const faq5 =
  'Qual o tipo de infraestrutura será entregue com a moradia? - abastecimento de água; - esgotamento sanitário; - energia elétrica; - iluminação pública; - iluminação pública; - redes de drenagem de águas pluviais (água da chuva); -pavimentação. IMPORTANTE!, É importante lembrar que serão atendidas as necessidades da região que vai receber o imóvel.'
export const faq6 =
  'Irei participar de algum sorteio para receber os 20 mil reais? Não. Se você cumprir todos os requisitos do Programa Entrada Moradia Ceará e do Programa Minha Casa, Minha Vida, os R$20.000,00 serão liberados diretamente para a Caixa Econômica Federal diminuir o valor da entrada do imóvel que você escolheu.'
export const faq7 =
  'Quais as informações obrigatórias que preciso preencher para me cadastrar no Programa? 1º - Mantenha o seu cadastro sempre atualizado. Isto é muito importante para que a gente possa entrar em contato com você sempre que necessário. 2º - Todas as informações serão enviadas por e-mail. Primeiro você precisará informar o número de avaliação de crédito e o APF do empreendimento onde pretende comprar um imóvel. Depois serão solicitados os seguintes dados: 3º - Documentos para responsável familiar:  CPF , e-mail, nome completo do responsável familiar,  nome completo da mãe do responsável familiar, possui algum tipo de deficiência?, data de nascimento, estado civil,  tempo de residência no imóvel atual (em anos), tempo de residência no município atual (em anos), tempo de serviço no emprego atual (em anos), tipo de renda, valor da renda bruta* em reais, número de celular, endereço completo: nome da avenida, rua, travessa, número, bairro, cidade, Estado e CEP, a família recebe benefício de transferência de renda? Benefício de Prestação Continuada (BPC)?, a família recebe Bolsa Família ou algum outro tipo de benefício?. Documentações para: Marido/esposa/companheiro/companheira (se houver), CPF, nome completo, nome completo da mãe, possui algum tipo de deficiência?, data de nascimento, estado civil, tempo de serviço no emprego atual (em anos), tipo de renda, valor da renda* bruta em reais, número de celular, Renda bruta é o valor total que você recebe, sem os descontos obrigatórios (de impostos, por exemplo). Certidão Negativa Municipal - do município que reside,  Certidão Negativa Municipal - do município do imóvel escolhido, Certidão Negativa Estadual'
export const faq8 =
  'Como funciona o Programa Entrada Moradia Ceará? O Programa Entrada Moradia Ceará oferece um benefício no valor de R$20.000,00 para os moradores do Estado do Ceará que recebam até R$4.400,00. O dinheiro deve ser usado como pagamento da entrada na compra do primeiro imóvel, financiado pelo Programa Minha Casa, Minha Vida. Com isso, o valor total da entrada do imóvel é reduzida ou mesmo zerada, tornando possível e acessível para mais famílias a compra do seu primeiro imóvel. Além disso, as construtoras deverão liberar você das custas com o Imposto de Transmissão de Bens Imóveis (ITBI) e o registro do imóvel.'
export const faq9 =
  'Sou cearense. Posso participar se eu morar em outro estado do Brasil? Não. Só podem participar do Programa Entrada Moradia Ceará pessoas que morem no Estado do Ceará há pelo menos 1 ano. IMPORTANTE! Você pode ter nascido em qualquer estado do Brasil, ser brasileiro naturalizado ou estrangeiro.'
export const faq10 =
  'Como posso usar os R$20.000,00 do Programa Entrada Moradia Ceará? Você pode usar o dinheiro para diminuir ou zerar o valor da entrada que você vai dar para financiar o seu imóvel.'
export const faq11 =
  'Em quanto tempo o dinheiro será liberado? Não é possível determinar um prazo exato. Depois que as etapas que dependem de você e da Caixa Econômica Federal terminarem (até o passo 11), a Secretaria das Cidades deve levar 15 dias úteis para analisar o pedido. A quantidade de dias pode variar, a depender do volume de pedidos.'
export const faq12 =
  'Moro no Ceará, mas não sou cearense. Posso participar? SIm. Para participar, você pode ser: brasileiro(a) nato(a) (é brasileiro(a) desde o momento em que nasceu); brasileiro(a) naturalizado(a) (quando você consegue a nacionalidade brasileira depois de um processo de naturalização. Explicando melhor: você é estrangeiro(a) e conseguiu a nacionalidade de um país diferente do que você nasceu); estrangeiro ou estrangeira. IMPORTANTE! Se você for estrangeiro(a) e quiser participar do Programa Entrada Moradia Ceará, você deve se informar para saber se preenche os requisitos definidos pela Caixa Econômica Federal.'
export const faq13 =
  'Meu filho mora comigo e trabalha. A renda dele também pode entrar para somar à nossa renda? A regra geral é que seja apresentada a renda do responsável financeiro (a pessoa que for fazer o cadastro) e se a pessoa for casada ou viver em união estável, pode juntar essa renda também. A depender do valor do imóvel, a Caixa Econômica Federal irá orientar o responsável financeiro da melhor forma sobre incluir ou não outros familiares.'
export const faq14 =
  'Preciso anexar algum documento para me cadastrar no Programa? Sim. Você deverá apresentar dois comprovantes de endereço: um atual e outro de, pelo menos, 12 meses atrás, para comprovar que reside no Estado do Ceará há pelo menos 1 ano. Explicando melhor: se o comprovante de endereço atual for do mês de janeiro de 2024, você vai precisar anexar um comprovante de janeiro de 2023 ou mais antigo. Os comprovantes de endereço não precisam ser da mesma empresa e podem ser em nome de qualquer um dos membros familiares que for receber o benefício. Observação: serão aceitos qualquer documento para comprovar onde você mora (água, luz, telefone, gás, fatura de cartão de crédito, entre outros).'
export const faq15 =
  'O que faço se minha avaliação de crédito não for aprovada pela Caixa Econômica Federal? Você pode escolher outro empreendimento e tentar novamente.'
export const faq16 =
  'Só pode participar do Programa quem trabalhar com carteira assinada? Não. Quando você fizer o seu cadastro, terá uma pergunta sobre o tipo de renda que você recebe.'
export const faq17 =
  'Se eu tiver dinheiro guardado e conseguir o benefício, posso juntar esses valores para dar de entrada no imóvel? Sim. O benefício do Programa é cumulativo com outros benefícios e recursos que você tenha guardado (por exemplo, poupança ou FGTS). O que vai definir a possibilidade de compra do imóvel é a sua capacidade de endividamento (se você vai conseguir pagar as parcelas do financiamento, pois você só pode comprometer até 33% de sua renda).'

export const askEmail =
  'Não achou sua dúvida? Entre em contato conosco pelo endereço de e-mail:  entrada.moradia@cidades.ce.gov.br'
