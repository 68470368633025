import styled, { css } from 'styled-components'

import BgImgPrimaryTexture from '@/../public/img/textura-principal.png'

export const TexturaBg = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgPrimaryTexture.src});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
`

export const ContainerFAQSection = styled.section`
  position: relative;

  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) => `0px ${theme.spacings.large} 8rem`};
  background-color: ${({ theme }) => theme.colors.infoDark};

  > div.content {
    width: 100%;
    max-width: 1140px;
    margin-top: 9rem;

    z-index: 100;
  }
`

export const ContentFAQ = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5.5rem;

  > div.header-faq {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    > .wrapper-svg {
      margin-top: 14rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 17rem;
    }

    > .faq-title {
      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: 4.8rem;
      color: ${({ theme }) => theme.colors.white};
    }

    > .faq-text {
      font-family: ${({ theme }) => theme.font.secondary};
      font-weight: ${({ theme }) => theme.font.normal};
      font-size: 1.8rem;
      color: ${({ theme }) => theme.colors.white};
      line-height: 3rem;

      &.link {
        font-weight: ${({ theme }) => theme.font.bold};
        text-decoration: underline;

        transition: opacity 200ms ease-in-out;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  > div.wrapper-faq {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 630px) {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;

    > div.header-faq {
      > .wrapper-svg {
        display: none;
      }

      > .faq-title {
        font-weight: ${({ theme }) => theme.font.semiBold};
        font-size: 4.8rem;
        color: ${({ theme }) => theme.colors.white};
      }

      > .faq-text {
        font-family: ${({ theme }) => theme.font.secondary};
        font-weight: ${({ theme }) => theme.font.normal};
        font-size: 1.8rem;
        color: ${({ theme }) => theme.colors.white};
        line-height: 3rem;
      }
    }
  }
`

export const FaqContent = styled.div<{
  $isOpen: boolean
  $adjustBorder: boolean
}>`
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  line-height: ${({ theme }) => theme.font.sizes.xlarge};

  border-bottom: 1px solid
    ${({ theme, $adjustBorder }) =>
      $adjustBorder ? `transparent` : theme.colors.white};

  > div.header {
    cursor: pointer;
    padding: 1.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: ${({ theme }) => theme.colors.white};
    }

    .question {
      flex: 1;
      font-weight: ${({ theme }) => theme.font.normal};
      margin: 0 1rem;
    }

    .button {
      flex-shrink: 0;
    }

    transition: background-color 200ms ease-in-out;
    &:hover {
      background-color: ${({ theme }) => theme.colors.info};
    }
  }

  > div.main {
    width: 100%;
    max-width: 800px;
    white-space: pre-line;

    border-radius: 8px;
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.white};

    padding: 0px 3.2rem 2.4rem 5rem;
    ${({ $isOpen }) =>
      !$isOpen &&
      css`
        display: none;
      `}
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};

      > div.header {
        border-radius: 8px;
        > .icon {
          color: ${({ theme }) => theme.colors.black};
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray300};
        }
      }

      > div.main {
        color: ${({ theme }) => theme.colors.black};
      }
    `}
`
