import { styled } from 'styled-components'

export const ClosedModal = styled.div`
  cursor: pointer;
  user-select: none;

  position: absolute;
  top: 15px;
  right: 27px;

  padding: 4px 6px;
  border-radius: 4px;

  span:first-of-type {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.font.bold};

    margin-right: 10px;
  }

  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`

export const WrapperSimpleModal = styled.div<{
  $type: 'success' | 'danger'
}>`
  display: flex;
  flex-direction: column;

  padding: 28px 9px 17px;

  div.title {
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-size: 2rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.font.bold};

    margin-bottom: 1.4rem;
  }

  div.message {
    font-family: ${({ theme }) => theme.font.secondary};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.font.normal};
    line-height: 2.8rem;
    margin-bottom: 3.4rem;
  }
`
