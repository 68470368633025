import dynamic from 'next/dynamic'
import { UploadOutlined } from '@ant-design/icons'

import { TextReaderButton } from '@/components/TextReaderButton'
import { SkeletonUI } from '@/components/UI/SkeletonUI'
import { ButtonUI } from '@/components/UI/ButtonUI'

import { videoDuvidas } from '../constants'

const ReactPlayer = dynamic(() => import('react-player'), {
  ssr: false,
  loading: () => <SkeletonUI width="100%" height="100%" />
})

import * as S from './styles'

const base_path = process.env.NEXT_PUBLIC_BASE_PATH || ''

export function VideoSession() {
  return (
    <S.ContainerProgramSection>
      <div className="content">
        <S.GridVideo>
          <div className="grid-video-left">
            <div>
              <TextReaderButton
                idReading="home-video-session#1"
                textReading={videoDuvidas}
              />
              <span className="title">Conheça mais sobre o Programa!</span>
              <span className="description">
                Baixe o folder explicativo com o passo a passo de como solicitar
                seu benefício no botão a seguir.
              </span>
              <div>
                <a
                  href={`${base_path}/pdf/FOLDER_SCIDADES.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ButtonUI
                    buttonType="secondary"
                    size="medium"
                    iconRN={<UploadOutlined style={{ fontSize: 17 }} />}
                  >
                    Baixar folder explicativo
                  </ButtonUI>
                </a>
              </div>
            </div>
          </div>
          <div className="grid-video-right">
            <div>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=5-wCI-fOdX8"
                width="100%"
                controls={true}
              />
            </div>
          </div>
        </S.GridVideo>
      </div>
      <S.TexturaBg $size="auto" />
    </S.ContainerProgramSection>
  )
}
