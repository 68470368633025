import { Modal, ModalProps } from 'antd'
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from '@ant-design/icons'

import { ButtonUI } from '@/components/UI/ButtonUI'

import * as S from './styles'

interface SimpleModalProps extends ModalProps {
  type?: 'success' | 'danger'
  onClose?: () => void
  onUnderstand: () => void | Promise<void>
  width: string
  title: string
  message: string
  openModal: boolean
}

export function SimpleModal({
  type = 'success',
  onClose,
  width,
  openModal,
  title,
  message,
  onUnderstand,
  ...props
}: SimpleModalProps) {
  const handleUnderstand = async () => {
    await onUnderstand()
  }

  return (
    <Modal
      open={openModal}
      onCancel={onClose}
      footer={null}
      closable={false}
      centered
      width={width}
      {...props}
    >
      {!!onClose && (
        <S.ClosedModal
          onClick={() => {
            onClose()
          }}
        >
          <span>Fechar</span>
          <CloseCircleOutlined />
        </S.ClosedModal>
      )}

      <S.WrapperSimpleModal $type={type}>
        <div className="title">
          {type === 'success' ? (
            <CheckCircleOutlined
              style={{
                color: '#1C8E38',
                fontSize: '3.2rem'
              }}
            />
          ) : (
            <ExclamationCircleOutlined
              style={{
                color: '#E30447',
                fontSize: '3.2rem'
              }}
            />
          )}
          <span>{title}</span>
        </div>
        <div className="message">
          <span>{message}</span>
        </div>
        <ButtonUI
          size="small"
          onClick={handleUnderstand}
          buttonType="secondary"
        >
          Entendi
        </ButtonUI>
      </S.WrapperSimpleModal>
    </Modal>
  )
}
