import React from 'react'

import * as S from './styles'

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string
  height?: string
  borderRadius?: string
}

export function SkeletonUI({
  width,
  height,
  borderRadius,
  ...rest
}: SkeletonProps) {
  return (
    <S.SkeletonPulse
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      {...rest}
    />
  )
}
