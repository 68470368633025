'use client'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'

import { SimpleModal } from '@/components/modals/SimpleModal'

import {
  ModalStateTextReaderType,
  TextReaderContextType
} from './textReaderType'

const AppAccessibilityData: TextReaderContextType = {
  readingId: undefined,
  loadingVoices: false,
  readText: () => {}
}

const TextReaderContext =
  createContext<TextReaderContextType>(AppAccessibilityData)

function TextReaderProvider({ children }: { children: React.ReactNode }) {
  const [selectedVoice, setSelectedVoice] =
    useState<SpeechSynthesisVoice | null>(null)

  const [statusVoices, setStatusVoices] = useState<
    'loading' | 'ready' | 'error'
  >('loading')
  const [readingId, setReadingId] = useState<string | undefined>(undefined)
  const [modalState, setModalState] = useState<ModalStateTextReaderType>({
    type: undefined,
    isOpen: false,
    title: '',
    description: ''
  })

  useEffect(() => {
    if (!('speechSynthesis' in window)) {
      setStatusVoices('error')
      console.warn('SpeechSynthesis API is not supported in this browser.')
      return
    }

    const synth = window.speechSynthesis

    const handleVoicesChanged = () => {
      const voices = synth.getVoices()
      let selected = voices.find(
        (voice) =>
          voice.name.includes('Microsoft Thalita') && voice.lang === 'pt-BR'
      )

      if (!selected) {
        selected = voices.find((voice) => voice.lang === 'pt-BR')
      }

      setStatusVoices('ready')
      setSelectedVoice(
        selected || voices.find((voice) => voice.default) || null
      )
    }

    const voices = synth.getVoices()
    if (voices.length > 0) {
      handleVoicesChanged()
    } else {
      synth.addEventListener('voiceschanged', handleVoicesChanged)
    }

    return () => {
      synth.removeEventListener('voiceschanged', handleVoicesChanged)
    }
  }, [])

  const readText = useCallback(
    (text: string, newIdReading: string) => {
      if (readingId) {
        if (readingId === newIdReading) {
          setReadingId(undefined)
          window.speechSynthesis.cancel()

          return
        }
        window.speechSynthesis.cancel()
      }

      if (statusVoices === 'error') {
        setModalState({
          type: 'danger',
          isOpen: true,
          title: 'Seu navegador não suporta leitura em voz alta.',
          description:
            'Para utilizar esse recurso, tente de novo em outro navegador.'
        })

        return
      }

      if (selectedVoice) {
        const speech = new SpeechSynthesisUtterance()
        speech.voice = selectedVoice
        speech.text = text
        setReadingId(newIdReading)

        speech.onend = () => {
          setReadingId(undefined)
        }

        window.speechSynthesis.speak(speech)

        return
      }

      setModalState({
        type: 'danger',
        isOpen: true,
        title: 'Seu dispositivo não suporta leitura em voz alta em Português.',
        description: 'Por favor, tente em outro dispositivo ou navegador.'
      })
    },
    [readingId, selectedVoice, statusVoices]
  )

  const handleClosedModal = () => {
    setModalState({
      type: 'danger',
      isOpen: false,
      title: '',
      description: ''
    })
  }

  const providerValue = useMemo(
    () => ({ readingId, readText, loadingVoices: statusVoices === 'loading' }),
    [readingId, readText, statusVoices]
  )

  return (
    <TextReaderContext.Provider value={providerValue}>
      {children}

      <SimpleModal
        openModal={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.description}
        width="450px"
        onClose={handleClosedModal}
        onUnderstand={handleClosedModal}
      />
    </TextReaderContext.Provider>
  )
}

const useTextReader = () => {
  const context = useContext(TextReaderContext)

  if (!context) {
    throw new Error(`useTextReader must be used within an TextReaderProvider`)
  }

  return context
}

export { TextReaderProvider, useTextReader }
