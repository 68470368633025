export const dataRequirementsCitizen = [
  {
    id: 'data-requirements-citizen-id-01',
    rotated: 0,
    text: 'Ter renda bruta familiar de até R$4.400,00 (quatro mil e quatrocentos reais)'
  },
  {
    id: 'data-requirements-citizen-id-02',
    rotated: 0,
    text: 'Morar no Estado do Ceará há pelo menos 1 ano'
  },
  {
    id: 'data-requirements-citizen-id-03',
    rotated: 180,
    text: 'Não possuir, não ter direito e não estar em processo de compra de um imóvel'
  },
  {
    id: 'data-requirements-citizen-id-04',
    rotated: 90,
    text: 'Não ter sido beneficiado por atendimento habitacional definitivo em nenhum programa habitacional de interesse social em qualquer lugar do país'
  },
  {
    id: 'data-requirements-citizen-id-05',
    rotated: 180,
    text: 'Ter avaliação inicial de crédito aprovada pelo Correspondente Bancário ou funcionário da Caixa Econômica Federal'
  },
  {
    id: 'data-requirements-citizen-id-06',
    rotated: 90,
    text: 'Fazer o cadastro no Programa Entrada Moradia Ceará'
  },
  {
    id: 'data-requirements-citizen-id-07',
    rotated: 270,
    text: 'Ter o cadastro aprovado pela Secretaria das Cidades'
  },
  {
    id: 'data-requirements-citizen-id-08',
    rotated: 34,
    text: 'Ter a aprovação da operação de crédito individual pela Caixa Econômica Federal'
  },
  {
    id: 'data-requirements-citizen-id-09',
    rotated: 90,
    text: 'Ter recursos orçamentário e financeiros disponíveis no Governo do Estado do Ceará'
  }
]

export const dataRequirementStep = [
  {
    id: 'data-requirements-step-id-01',
    numberItem: 1,
    rotated: 0,
    text: `Veja as moradias no site: www.entradamoradia.ce.gov.br ↗ visite os empreendimentos credenciados e escolha um.`
  },
  {
    id: 'data-requirements-step-id-02',
    numberItem: 2,
    rotated: 0,
    text: `Simule o financiamento com um correspondente bancário ou funcionário da Caixa. Se o crédito for pré-aprovado, solicite:\n
    * O número da avaliação de crédito.\n
    * O código de Acompanhamento de Programa de Fomento (APF) do imóvel.`
  },
  {
    id: 'data-requirements-step-id-03',
    numberItem: 3,
    rotated: 180,
    text: 'Cadastre-se no site do programa, envie os documentos solicitados e o Termo de Adesão e Compromisso assinados.'
  },
  {
    id: 'data-requirements-step-id-04',
    numberItem: 4,
    rotated: 90,
    text: 'Com tudo certo, a Secretaria das Cidades disponibilizará o seu Comprovante de Cadastro de Interesse (CCI).'
  },
  {
    id: 'data-requirements-step-id-05',
    numberItem: 5,
    rotated: 180,
    text: 'Acompanhe, com seu correspondente bancário, a tramitação do processo de Financiamento junto à Caixa.'
  },
  {
    id: 'data-requirements-step-id-06',
    numberItem: 6,
    rotated: 90,
    text: 'Nesse passo, será feita a liberação do certificado de subsídio. Em seguida, você assinará o contrato de financiamento.'
  }
]
