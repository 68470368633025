import { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import Link from 'next/link'

import { TextReaderButton } from '@/components/TextReaderButton'

import { dataRequirementStep, dataRequirementsCitizen } from './dataRequirement'

import {
  empresasCredenciamento,
  passoAPassoCidadao,
  requisitosCidadao
} from '../constants'

import * as S from './styles'

interface IProps {
  type: 'user' | 'company'
}

export default function HowToParticipateRequirement({ type }: IProps) {
  const [isOpenRequirementsCitizen, setIsOpenRequirementsCitizen] =
    useState(false)
  const [isOpenRequirementsStep, setIsOpenRequirementsStep] = useState(false)

  switch (type) {
    case 'user':
      return (
        <S.ContainerHowToUserRequirement
          $openRequirementsCitizen={isOpenRequirementsCitizen}
          $openRequirementsStep={isOpenRequirementsStep}
        >
          <div className="card-requirement-user">
            <div
              className="header-card"
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsOpenRequirementsCitizen((prev) => !prev)
                setIsOpenRequirementsStep(false)
              }}
            >
              <div>
                {isOpenRequirementsCitizen ? <UpOutlined /> : <DownOutlined />}

                <span style={{ paddingLeft: '8px' }}>Requisitos - Cidadão</span>
              </div>

              <TextReaderButton
                idReading="home-how-to-participate-requirement#1"
                textReading={requisitosCidadao}
              />
            </div>

            <div className="wrapper-info-requirement-user">
              {dataRequirementsCitizen.map(({ id, rotated, text }) => (
                <S.CardRequirement $rotated={rotated} key={id}>
                  <div className="wraper-geo-img">
                    <svg
                      className="rotated"
                      width="85"
                      height="84"
                      viewBox="0 0 85 84"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="42"
                        cy="41.832"
                        r="29"
                        fill="#E4591E"
                        stroke="#E4591E"
                        strokeWidth="2"
                      />
                      <path
                        d="M42.8859 39.9914L42.875 39.9801L42.8638 39.9693L4.4111 2.71824C3.7657 2.09301 4.20832 1 5.10689 1L82.168 1C82.7203 1 83.168 1.44772 83.168 2L83.168 79.0645C83.168 79.9629 82.0754 80.4056 81.4501 79.7607L42.8859 39.9914Z"
                        stroke="#FBC543"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                  <div className="text-card">
                    <span>{text}</span>
                  </div>
                </S.CardRequirement>
              ))}
            </div>
          </div>

          <div className="divisor" />

          <div className="card-requirement-user">
            <div
              className="header-card"
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={() => {
                setIsOpenRequirementsStep((prev) => !prev)
                setIsOpenRequirementsCitizen(false)
              }}
            >
              <div>
                {isOpenRequirementsStep ? <UpOutlined /> : <DownOutlined />}
                <span style={{ paddingLeft: '8px' }}>
                  Passo a passo - Cidadão
                </span>
              </div>

              <TextReaderButton
                idReading="home-how-to-participate-requirement#2"
                textReading={passoAPassoCidadao}
              />
            </div>

            <div className="wrapper-info-requirement-step">
              {dataRequirementStep.map(({ id, rotated, text, numberItem }) => {
                if (numberItem === 1) {
                  return (
                    <S.CardRequirementStep $rotated={rotated} key={id}>
                      <div className="wraper-geo-img">
                        <span>{numberItem}</span>
                        <svg
                          className="rotated"
                          width="106"
                          height="76"
                          viewBox="0 0 106 76"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M51.8667 52.8707L51.8523 52.8647L51.8377 52.8592L1.80357 33.811C0.96379 33.4912 0.939033 32.3123 1.76464 31.9576L72.5684 1.53999C73.0759 1.32199 73.664 1.55663 73.882 2.06407L104.301 72.8711C104.656 73.6965 103.826 74.5345 102.997 74.1888L51.8667 52.8707Z"
                            stroke="#FBC543"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                      <div className="text-card">
                        <span>
                          Veja as moradias no site:{' '}
                          <Link href={'/'}>
                            www.entradamoradia.ce.gov.br ↗
                          </Link>{' '}
                          visite os empreendimentos credenciados e escolha um.
                        </span>
                      </div>
                    </S.CardRequirementStep>
                  )
                }
                return (
                  <S.CardRequirementStep $rotated={rotated} key={id}>
                    <div className="wraper-geo-img">
                      <span>{numberItem}</span>
                      <svg
                        className="rotated"
                        width="106"
                        height="76"
                        viewBox="0 0 106 76"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M51.8667 52.8707L51.8523 52.8647L51.8377 52.8592L1.80357 33.811C0.96379 33.4912 0.939033 32.3123 1.76464 31.9576L72.5684 1.53999C73.0759 1.32199 73.664 1.55663 73.882 2.06407L104.301 72.8711C104.656 73.6965 103.826 74.5345 102.997 74.1888L51.8667 52.8707Z"
                          stroke="#FBC543"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                    <div className="text-card">
                      <span>{text}</span>
                    </div>
                  </S.CardRequirementStep>
                )
              })}
            </div>
          </div>
        </S.ContainerHowToUserRequirement>
      )
    case 'company':
      return (
        <S.ContainerHowToParticipateRequirement>
          <div className="requirement-company">
            <div
              style={{
                display: 'grid',
                gap: '12px'
              }}
            >
              <div>
                <TextReaderButton
                  idReading="home-how-to-participate-requirement#3"
                  textReading={empresasCredenciamento}
                />
              </div>
              <div>
                <span>
                  Credencie sua empresa no Programa Entrada Moradia Ceará,{' '}
                  <Link href="/entrar-empresa" target="_blank" rel="noreferrer">
                    acessando a página de cadastro. ↗
                  </Link>
                </span>
              </div>
            </div>

            <span>
              Esta área é exclusiva para cadastrar empresas do ramo da
              construção civil que tenham interesse em apresentar imóveis
              aprovados pela Caixa Econômica Federal que cumpram os requisitos
              do Programa Minha Casa, Minha Vida. O objetivo desta seção é
              atender aos beneficiários do Programa Entrada Moradia Ceará.
            </span>
          </div>
        </S.ContainerHowToParticipateRequirement>
      )
    default:
      return <></>
  }
}
