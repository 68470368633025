'use client'

import { TextReaderProvider } from '@/context/textReaderContext'

import { HowToParticipateSection } from './HowToParticipateSection'
import { PresentationSection } from './PresentationSection'
import { ProgramSection } from './ProgramSection'
import { VideoSession } from './VideoSession'
import { FAQSection } from './FAQSection'
import { ASKhelper } from './ASKhelper'

import * as S from './styles'

export function Home() {
  return (
    <TextReaderProvider>
      <S.ContentHome>
        <PresentationSection />
        <ProgramSection />
        <VideoSession />
        <HowToParticipateSection />
        <FAQSection />
        <ASKhelper />
      </S.ContentHome>
    </TextReaderProvider>
  )
}
