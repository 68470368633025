import Image from 'next/image'
import { BankOutlined, FileTextOutlined, ShopOutlined } from '@ant-design/icons'
import { useRouter } from 'next/navigation'

import { PublicHeader } from '@/components/header/PublicHeader'

import backgroundImage from '../../../../public/img/pagina-inicial/casal-inicial-grande.png'
import backgroundImageMid from '../../../../public/img/oficiais/701fadef4e7d78ad72632853a2f3edbe.png'

import * as S from './styles'

export function PresentationSection() {
  const router = useRouter()

  return (
    <S.ContainerPresentationSection>
      <S.ImgBg>
        <Image
          alt=""
          src={backgroundImage}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          quality={60}
          priority
          blurDataURL={S.urlLinkImageBg}
          placeholder="blur"
        />
      </S.ImgBg>

      <div className="content">
        <S.WrapperInfo>
          <PublicHeader />
          <S.MainInfo>
            <div className="main-info-left">
              <S.TexturaBg />
              <span className="special">UMA</span>
              <span>MORADIA</span>
              <span className="special">MAIS DIGNA</span>
              <span className="special">PARA O</span>
              <span>CEARENSE</span>
            </div>
            <div className="main-info-right">
              <Image
                alt=""
                src={backgroundImageMid}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                priority
                quality={80}
                blurDataURL={S.urlLinkImageBg}
                placeholder="blur"
              />
            </div>
          </S.MainInfo>
          <S.FooterInfo>
            <div
              className="card-info first"
              onClick={() => {
                router.push('/entrar')
              }}
            >
              <div className="icon-info">
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="white"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="23"
                    stroke="#1B98E0"
                    strokeWidth="0"
                  />
                  <path
                    d="M34.784 31.5859C34.2152 30.2387 33.3899 29.015 32.3538 27.9829C31.321 26.9479 30.0975 26.1226 28.7508 25.5528C28.7388 25.5467 28.7267 25.5437 28.7146 25.5377C30.593 24.1809 31.8141 21.9709 31.8141 19.4774C31.8141 15.3467 28.4674 12 24.3368 12C20.2061 12 16.8594 15.3467 16.8594 19.4774C16.8594 21.9709 18.0805 24.1809 19.9589 25.5407C19.9468 25.5467 19.9347 25.5497 19.9227 25.5558C18.5719 26.1256 17.3599 26.9427 16.3197 27.9859C15.2846 29.0188 14.4594 30.2423 13.8895 31.5889C13.3297 32.9073 13.0278 34.3207 13.0001 35.7528C12.9993 35.7849 13.0049 35.817 13.0167 35.8469C13.0284 35.8769 13.0461 35.9042 13.0686 35.9272C13.091 35.9503 13.1179 35.9686 13.1476 35.9811C13.1772 35.9936 13.2091 36 13.2413 36H15.0503C15.183 36 15.2885 35.8945 15.2915 35.7648C15.3518 33.4372 16.2865 31.2573 17.9388 29.605C19.6483 27.8955 21.9187 26.9548 24.3368 26.9548C26.7548 26.9548 29.0252 27.8955 30.7347 29.605C32.387 31.2573 33.3217 33.4372 33.382 35.7648C33.385 35.8975 33.4905 36 33.6232 36H35.4322C35.4644 36 35.4963 35.9936 35.526 35.9811C35.5556 35.9686 35.5825 35.9503 35.605 35.9272C35.6274 35.9042 35.6451 35.8769 35.6568 35.8469C35.6686 35.817 35.6742 35.7849 35.6734 35.7528C35.6433 34.3116 35.3448 32.9095 34.784 31.5859ZM24.3368 24.6633C22.9528 24.6633 21.6503 24.1236 20.6704 23.1437C19.6905 22.1638 19.1508 20.8613 19.1508 19.4774C19.1508 18.0935 19.6905 16.791 20.6704 15.8111C21.6503 14.8312 22.9528 14.2915 24.3368 14.2915C25.7207 14.2915 27.0232 14.8312 28.0031 15.8111C28.983 16.791 29.5227 18.0935 29.5227 19.4774C29.5227 20.8613 28.983 22.1638 28.0031 23.1437C27.0232 24.1236 25.7207 24.6633 24.3368 24.6633Z"
                    fill="#1B98E0"
                  />
                </svg>
              </div>
              <div className="wrapper-text">
                <span>Solicitar benefício</span>
                <span className="link">Cadastre sua solicitação</span>
              </div>
            </div>

            <div
              className="card-info mid1"
              onClick={() => {
                router.push('/entrar-empresa')
              }}
            >
              <div className="icon-info">
                <BankOutlined />
              </div>
              <div className="wrapper-text">
                <span>Cadastrar empresa</span>
                <span className="link">Crie uma conta com CNPJ</span>
              </div>
            </div>

            <div
              className="card-info mid"
              onClick={() => {
                router.push('/documentos-oficiais')
              }}
            >
              <div className="icon-info">
                <FileTextOutlined />
              </div>
              <div className="wrapper-text">
                <span>Documentos oficiais</span>
                <span className="link">Lei, editais e chamamento</span>
              </div>
            </div>

            <div
              className="card-info"
              onClick={() => {
                router.push('/empreendimentos')
              }}
            >
              <div className="icon-info">
                <ShopOutlined />
              </div>
              <div className="wrapper-text">
                <span>Empreendimentos</span>
                <span className="link">Ver lista</span>
              </div>
            </div>
          </S.FooterInfo>
        </S.WrapperInfo>
      </div>
    </S.ContainerPresentationSection>
  )
}
