import { MdOutlinePlayCircleOutline, MdOutlineStopCircle } from 'react-icons/md'

import { ButtonUI } from '../UI/ButtonUI'

import { useTextReader } from '@/context/textReaderContext'

import * as S from './styles'

interface IProps {
  idReading: string
  textReading: string
}

export function TextReaderButton({ idReading, textReading }: IProps) {
  const { readingId, readText, loadingVoices } = useTextReader()

  return (
    <S.ContainerTextReaderButton className="container-text-reader-button">
      <ButtonUI
        className="btn-text-reader"
        buttonType={readingId === idReading ? 'danger' : 'secondary'}
        onClick={(e) => {
          e.stopPropagation()
          readText(textReading, idReading)
        }}
        loading={loadingVoices}
      >
        {readingId === idReading ? (
          <>
            Parar
            <MdOutlineStopCircle className="icon-text-reader" />
          </>
        ) : (
          <>
            Ouvir
            <MdOutlinePlayCircleOutline className="icon-text-reader" />
          </>
        )}
      </ButtonUI>
    </S.ContainerTextReaderButton>
  )
}
