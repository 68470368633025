import styled from 'styled-components'

import BgImgSecondaryTexture from '@/../public/img/textura-2.png'
import BgImgPrimaryTexture from '@/../public/img/textura-principal.png'

export const TexturaBg2 = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgSecondaryTexture.src});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 0;
`

export const TexturaBg = styled.div<{ $size: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: url(${BgImgPrimaryTexture.src});
  background-repeat: repeat;
  background-size: ${({ $size }) => $size};
  mix-blend-mode: multiply;
  opacity: 0.6;
  z-index: 0;
`

export const ContainerProgramSection = styled.section`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #1c8d38;

  min-height: 100vh;
  div.content {
    width: 100%;
    max-width: 1140px;
    z-index: 10;
  }

  > div.gradient-program {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0px ${theme.spacings.large}`};
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(26, 110, 50, 1) 0%,
      rgba(28, 140, 55, 1) 85%
    );
  }

  > div.solid-program {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0px ${theme.spacings.large}`};
    width: 100%;
    background: rgba(26, 110, 50, 1);
  }
`

export const HeaderProgram = styled.div`
  position: relative;

  width: 100%;
  height: 500px;
  background-color: #1c8d38;
  border-radius: 0px 0px 48px 48px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr;

  > div.header-program-left {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    z-index: 1;

    > .wrapper-img {
      position: absolute;
      bottom: -10px;

      margin-top: -5px;
      width: 100%;
      max-width: 500px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 15%;
      left: 30%;

      width: 300px;
      height: 300px;

      border: 2px solid ${({ theme }) => theme.colors.white};
      border-radius: 4px;
      transform: rotate(45deg);
    }
  }

  > div.header-program-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    padding: 0px 5rem 0px 4rem;

    > div.header-program-img {
      width: 100%;
      margin-bottom: 3rem;
    }

    > div.header-program-title {
      margin-bottom: 1.2rem;

      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: 3.2rem;
      color: ${({ theme }) => theme.colors.white};

      > .container-text-reader-button {
        margin-bottom: 1.6rem;
      }

      > span > span {
        color: ${({ theme }) => theme.colors.brandYellow400};
      }
    }
    > div.header-program-description {
      width: 100%;

      font-family: ${({ theme }) => theme.font.secondary};
      font-weight: ${({ theme }) => theme.font.normal};
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: ${({ theme }) => theme.colors.white};

      > span > span {
        color: ${({ theme }) => theme.colors.brandYellow400};
      }
    }
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
    height: 300px;

    > div.header-program-left {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    height: 400px;
    padding-top: 20px;

    > div.header-program-left {
      display: none;
    }
  }

  @media screen and (max-width: 370px) {
    > div.header-program-right {
      > div.header-program-title {
        font-size: 2.4rem;
      }
    }
  }
`

export const MainProgram = styled.div`
  margin: 9rem 0px;
  width: 100%;

  > div.header-main-program {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 5.6rem;

    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: 3.2rem;
    line-height: 4.5rem;
    color: ${({ theme }) => theme.colors.white};

    strong {
      color: ${({ theme }) => theme.colors.brandYellow400};
    }

    > div.header-main-program-right {
      position: relative;
      width: 100%;

      > div.square {
        position: absolute;
        top: 25%;
        left: 25%;
        width: 126px;
        height: 126px;
        border-radius: 8px;
        border: 2px solid ${({ theme }) => theme.colors.gray500};

        transform: rotate(40deg);
      }

      > div.triangle {
        position: absolute;
        top: 0;
        left: 25%;
      }
    }
  }

  > div.main-program-img {
    width: 100%;
    height: 420px;

    margin-bottom: 3.2rem;

    img {
      border-radius: 8px;
    }
  }

  > div.content-btn-text-reader {
    margin-bottom: 1.6rem;
  }

  > div.main-program-grid-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8rem;

    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: 1.8rem;
    line-height: 2.7rem;

    strong {
      font-weight: ${({ theme }) => theme.font.bold};
      color: ${({ theme }) => theme.colors.brandYellow400};
    }

    > div.item-grid {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: underline;

        transition: color 200ms ease-in-out;

        &:hover {
          color: ${({ theme }) => theme.colors.brandYellow400};
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    > div.header-main-program {
      grid-template-columns: 1fr;
      margin-bottom: 0rem;

      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: 2.8rem;
      line-height: 3.5rem;
      color: ${({ theme }) => theme.colors.white};

      strong {
        color: ${({ theme }) => theme.colors.brandYellow400};
      }

      > div.header-main-program-right {
        display: none;
      }
    }

    > div.main-program-img {
      height: auto;
      margin: 6.4rem 0px;

      img {
        border-radius: 8px;
        height: 100%;
      }
    }
    > div.main-program-grid-info {
      grid-template-columns: 1fr;
      grid-gap: 4rem;
    }
  }
`
