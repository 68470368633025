import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

export const SkeletonPulse = styled.div<{
  $width?: string
  $height?: string
  $borderRadius?: string
}>`
  display: inline-block;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '100%'};
  background: ${({ theme }) => theme.colors.gray500};
  border-radius: ${({ $borderRadius }) => $borderRadius || '4px'};
  animation: ${pulseAnimation} 1.5s ease-in-out infinite;
`
